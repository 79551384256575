
/* Imports */
import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService, SessionService } from '../../../shared/services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-system-use',
	templateUrl: './systemUse.component.html',
	styleUrls: ['./systemUse.component.scss'],
})
export class SystemUseComponent {

	constructor(
		public dialogRef: MatDialogRef<SystemUseComponent>,
		private _authenticationService: AuthenticationService,
		private _ngZone: NgZone,
		public _router: Router,
	) {
		this.dialogRef.disableClose = true;
	}

	accept() {
		SessionService.set("agreed", true);
		this.dialogRef.close();
	}

	decline() {
		this.dialogRef.close();
		this._authenticationService.logout().then(() => {
			this._ngZone.run(() => {
				this._router.navigate(['login']);
			});
		});
	}
}