
/* Imports */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from "@angular/material/stepper";

import { AlertService, AuthenticationService, SessionService, OrganizationService, UserService } from '@shared/services';
import {_Subscription} from "@shared/services/subscription.service";

import { Alert } from '@shared/models';
import { flagLayer, availableFeatureFlags } from '@shared/featureFlags';
import { environment } from '@/environments/environment';

@Component({
	selector: 'app-questionnaire',
	templateUrl: './questionnaire.component.html',
	styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

	@ViewChild(MatStepper) stepper: MatStepper;

	public user: any;
	public activePlan = null; 	// TODO: @remove remove-subscription-required

	public answers = {
		companyName: '',
		industry: '',
		phoneNumber: '',
		reasonForMapware: '',
		otherSoftware: '',
		supportLevel: null
	};

	public options = {
		industry: [
			'Aerospace',
			'Agriculture',
			'Architecture/Engineering/Construction',
			'Drone Services',
			'Energy/Utilities',
			'Engineering Services',
			'Environmental Services',
			'Hobbyist',
			'Insurance',
			'Government',
			'Software',
			'Telecommunications',
			'University/Higher Education',
			'Other'],
		reasonForMapware: [
			'Compliance/Regulatory',
			'Environmental Analysis',
			'Inspections',
			'Planning & Logistics',
			'Plant health',
			'Site Survey',
			'Tracking progress',
			'Other'],
		otherSoftware: [
			'ATAK',
			'Autodesk',
			'Bluebeem',
			'ESRI',
			'Github',
			'Jira',
			'Procore',
			'Salesforce',
			'Skyward',
			'Trimble',
			'UnrealEngine',
			'Other'],
		supportLevel: [
			'I\'m good, just checking things out',
			'I could use some help, but I\'m not sure how much',
			'I\'ll need custom integration and implementation support'],
	};

	public reasonOptions = [];
	public softwareOptions = [];
	public hasActiveTrial = false; 	// TODO: @remove remove-subscription-required
	public freeCredits: number = environment.freeCredits;

	constructor(
		public dialogRef: MatDialogRef<QuestionnaireComponent>,
		private _alertService: AlertService,
		private _authService: AuthenticationService,
		private _orgService: OrganizationService,
		private _userService: UserService
	) { }

	ngOnInit() {
		this.user = this._authService.user;

		// TODO: @remove remove-subscription-required : remove check for plan and trial
		this._orgService.
			getList()
			.then(([org]) => {
				this.activePlan = org?.subscription;
				// TODO: @remove remove-subscription-required
				if (!flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement)) this.hasActiveTrial = _Subscription.isInTrial(org);
		})
	}	// End-of ngOnInit

	get readyForNext(): boolean {
		return !!this.answers.companyName && !!this.answers.industry && this.answers.phoneNumber.length === 10 && this.answers.supportLevel !== null;
	}

	next() {
		// TODO: parse reasonOptions & softwareOptions, not doing now bc those pages are hidden atm
		this.user.descriptors.questionnaire = this.answers;
		const user = this.user;
		this._userService.update(user).then(() => {
			SessionService.set('user', user);
			this.dialogRef.close();
		}, err => {
			console.error(err);
			this._alertService.error(new Alert('Questionnaire failed to save, please try again later.'));
			this.dialogRef.close();
		});
	}


}	// End-of class TestComponent
