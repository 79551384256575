
/* Imports */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
// import { UserService } from '..//services';

@Injectable()
export class DevGuard  {

	constructor() {

	}	// End-of constructor

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return false;

	}	// End-of canActivate

	canActivateChild(): boolean {

		return false;

	}	// End-of canActivate

	canLoad(): boolean {

		return false;

	}	// End-of canLoad

}	// End-of class DevGuard