
<div class="search">
	<div class="search-content">
		<div class="search-button" (click)="openSearch()">
			<mat-form-field appearance="outline" class="search-bar" [ngClass]="{'active': searchOpen, 'keep-open': !searchOpen && searchValue.length}">
				<input data-testId="search-field"
					   [ngClass]="{'input-active': searchValue.length}"
					   matInput
					   [(ngModel)]="searchValue"
					   (ngModelChange)="searchFilter()"
					   placeholder="Search">
				<span class="input-tip">
					<p *ngIf="searchOpen && searchValue.length <= 1">
						{{searchValue.length === 1 ?
						"Enter two or more characters to search." :
						"Start typing to search."
						}}
					</p>
					<p *ngIf="!searchOpen && searchValue.length">Click to resume search.</p>
					<button *ngIf="searchValue" mat-icon-button class="clear-button" (click)="clearSearch(); $event.stopPropagation();">
						<mat-icon>close</mat-icon>
					</button>
				</span>
			</mat-form-field>
		</div>

		<div class="search-overlay" (click)="closeSearch()"></div>

		<div class="search-results"
			 *ngIf="canSearch() && loadChunkFunction">
			<app-scroll-load
				[chunkLoadFunction]="loadChunkFunction"
				[isLoading]="isLoading">
				<app-main-table
					[currentPage]="'search'"
					[isProcessing]="isLoading"
					[displayedColumns]="displayedColumns"
					[tableDataSource]="dataSource"
					(outEvent)="handleEvent($event);">
				</app-main-table>
			</app-scroll-load>
		</div>
	</div>
</div>
