<div class="wrapper">
	<main>
		<div class="H3Black700 header">{{user.first_name}}, let's customize your account.</div>
		<div class="H6Black500Regular subheader">
			Tell us a little more about yourself, so Mapware can create a tailored experience for you and your
			organization.
		</div>
		<ng-container *ngTemplateOutlet="whoAreYou"></ng-container>
		<ng-container *ngTemplateOutlet="howCanWeHelp"></ng-container>
		<div class="button-container">
			<div style="display: block;">
				<button mat-stroked-button color="primary" [disabled]='!readyForNext' (click)="next()">
					{{ 'Get started'}}
				</button>
			</div>
		</div>
	</main>
	<aside>
		<img src="./assets/images/aa_logo.png">

		<!-- TODO @remove remove-subscription-required -->
		<ng-container *featureEnabled="'!remove-subscription-required'">
			<ng-container *ngIf="activePlan && !hasActiveTrial">
				<div class="H5Black700">Your Professional Plan</div>
			</ng-container>
			<ng-container *ngIf="activePlan && hasActiveTrial">
				<div class="H5Black700">Your Free Trial</div>
			</ng-container>
			<div class="H6Black700Regular" style="margin-bottom: 24px;">Here's what's included:</div>
			<app-register-cycle [activePlan]="activePlan"></app-register-cycle>
		</ng-container>

		<ng-container *featureEnabled="'remove-subscription-required'">
			<div class="H5Black700">Your Mapware Plan</div>
			<div class="H6Black700Regular" style="margin-bottom: 24px;">Here's what's included:</div>
			<div class="feature-list">
				<div>
					<mat-icon class="feature-check">
						done
					</mat-icon>
					{{freeCredits | number:'1.0':'en-US'}} credits to get you started.
				</div>
				<div>
					<mat-icon class="feature-check">
						done
					</mat-icon>
					After using your {{freeCredits | number:'1.0':'en-US'}} credits, pay as you go.
				</div>
				<div>
					<mat-icon class="feature-check">
						done
					</mat-icon>
					Unlimited storage.
				</div>
			</div>
		</ng-container>
		<!-- @remove-end remove-subscription-required -->

	</aside>
</div>
<ng-template #whoAreYou>
	<div class="input-label">
		What is your company's name?
	</div>
	<mat-form-field appearance="fill">
		<input data-testid="company-name" matInput [(ngModel)]="answers.companyName">
	</mat-form-field>
	<div class="input-label">
		What industry do you work in?
	</div>
	<mat-form-field appearance="fill">
		<mat-select primary [(value)]="answers.industry" data-testid="industry">
			<mat-option *ngFor="let option of options.industry" [value]="option">
				{{option}}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<div class="input-label">
		What a good phone number to contact you on?
	</div>
	<mat-form-field appearance="fill">
		<input data-testid="phone-number" matInput [(ngModel)]="answers.phoneNumber" mask="(000) 000-0000">
	</mat-form-field>
</ng-template>
<ng-template #whyAreYouHere>
	<div class="input-label">What brings you here? (check any that apply)</div>
	<div *ngFor="let option of options.reasonForMapware; let i=index; last as last" class="H6Black700Regular"
		[style.margin-bottom]="last ? '24px' : '8px'">
		<mat-checkbox [(ngModel)]='reasonOptions[i]'>
			{{option}}
		</mat-checkbox>
	</div>
</ng-template>
<ng-template #whatElseDoYouUse>
	<div class="input-label">
		What other software do you use? We love building integrations! (check any that apply)
	</div>
	<div *ngFor="let option of options.otherSoftware; let i=index; last as last" class="H6Black700Regular"
		[style.margin-bottom]="last ? '24px' : '8px'">
		<mat-checkbox [(ngModel)]='softwareOptions[i]'>
			{{option}}
		</mat-checkbox>
	</div>
</ng-template>
<ng-template #howCanWeHelp>
	<div class="input-label">
		Before we send you off on your own... how can we best support you?
	</div>
	<mat-radio-group [(ngModel)]="answers.supportLevel">
		<mat-radio-button *ngFor="let option of options.supportLevel; let i=index; last as last" [value]="i"
			class="H6Black700Regular" [style.margin-bottom]="last ? '24px' : '8px'">
			{{option}}
		</mat-radio-button>
	</mat-radio-group>

</ng-template>
