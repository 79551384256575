<div class="container" data-testid="confirmation-modal">
	<h1 mat-dialog-title class="H2Black700 text-centered">
		Mapware Use Notification
	</h1>
	<div mat-dialog-content class="H6Black500Regular">
		<ul>
			<li>Mapware usage may be monitored or recorded and is subject to audit.</li>
			<li>Unauthorized use is prohibited and subject to criminal and civil penalties.</li>
			<li>Use of Mapware affirms consent to monitoring and recording.</li>
			<li>There is no expectation of privacy while using Mapware.</li>
			<li>Mapware may contain CUI with specific requirements imposed by the Department of Defense.</li>
			<li>Use of Mapware may be subject to other specified requirements associated with certain types
			of CUI such as Export Controlled information.</li>
		</ul>
	</div>

	<div mat-dialog-actions class="dialog-actions">
		<button
			mat-flat-button
			class="accept-button"
			color="primary"
			autofocus
			(click)="accept()">
			Accept
		</button>

		<button
			mat-button
			class="decline-button"
			(click)="decline()">
			Decline
		</button>
	</div>
</div>
