
<div class="stepper-container onboarding-stepper">
	<span class="text-container">
		<div class="LabelBlack500 uppercase" style="margin: 80px 64px 0 64px;">Get started</div>
		<div class="horizontal-stepper-container stepper-with-dots">
			<mat-horizontal-stepper #testStepper (selectionChange)="selectionChange($event)" [@.disabled]="true">
				<mat-step style="text-align: center;">
					<div style="text-align: left; margin: 0 64px;">
						<div class="H2Black700" style="margin: 8px 0;">Hello {{userName}}!</div>
						<div class="H6Black700Regular">Welcome to Mapware: A powerful and intuitive photogrammetry software accessible anytime, anywhere.</div>
						<button mat-stroked-button color="primary" style="margin-top: 24px; width: 118px;" matStepperNext>Next</button>
					</div>
				</mat-step>
				<mat-step style="text-align: center;">
					<div style="text-align: left; margin: 0 64px;">
						<div class="H2Black700" style="margin: 8px 0;">Create projects</div>
						<div class="H6Black700Regular">Create and name your projects, and upload all your photos to process.</div>
						<div class="H6Black700Regular" style="margin-top: 16px;">Track all your relevant photogrammetry files in one place!</div>
						<button mat-stroked-button color="primary" style="margin-top: 24px; width: 118px;" matStepperNext>Next</button>
					</div>
				</mat-step>
				<mat-step style="text-align: center;">
					<div style="text-align: left; margin: 0 64px;">
						<div class="H2Black700" style="margin: 8px 0;">Process models</div>
						<div class="H6Black700Regular">Use your credits to process your photos into a model. You may configure the resolution, tiepoints, and keypoints for your model.</div>
						<button mat-stroked-button color="primary" style="margin-top: 24px; width: 118px;" matStepperNext>Next</button>
					</div>
				</mat-step>
				<mat-step style="text-align: center;">
					<div style="text-align: left; margin: 0 64px;">
						<div class="H2Black700" style="margin: 8px 0;">Export models</div>
						<div class="H6Black700Regular">After your model is processed, export it in the file type that best fits your needs, so it's ready to use in your next workflow.</div>
						<button mat-stroked-button color="primary" style="margin-top: 24px; width: 118px;" matStepperNext>Next</button>
					</div>
				</mat-step>
				<mat-step style="text-align: center;">
					<div style="text-align: left; margin: 0 64px;">
						<div class="H2Black700" style="margin: 8px 0;">Collaborate as a team</div>
						<div class="H6Black700Regular">Share your projects with team members to collaborate on all of your photogrammetry data. Say goodbye to manually sending links to different people!</div>
						<div class="H6Black700Regular" style="margin-top: 16px;">Simply use the share function to easily share multiple files with your team and collaborators.</div>
						<button mat-stroked-button color="primary" style="margin-top: 24px; width: 100%;" matDialogClose>Let's get started!</button>
					</div>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
		<button mat-button class="step-close" matDialogClose>Skip tour</button>
	</span>
	<span class="image-container">
		<span class="image-view" *ngFor="let image of imageList; let i = index;" [class]="image.class" [ngClass]="{'hidden' : selectedImage != i}">
			<img [src]="image.image">
		</span>
	</span>
</div>
