
/* Imports */
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { merge, uniq } from 'lodash';

/* Services */
import {
	AlertService,
	FavoriteService,
	Favorite,
	SearchService,
	GlobalService,
	UtilsService,
	OrganizationService,
	assignTypesAndConvert,
	uniqueByKeys
} from '@shared/services';
import { Alert, Organization } from 'src/app/shared/models';
import { Subscription } from 'rxjs';

const defaultEmptyText = 'Type in a search value above to start searching.';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, OnDestroy {

	/* ViewChild(ren) */
	@ViewChild(MatSort) set sort( s: MatSort ) {
		if ( s ) { this.dataSource.sort = s; }
	};

	public emptyText: string = defaultEmptyText;

	public searchValue = '';
	public staticSearchValue = '';
	public dataSource: MatTableDataSource<any> = new MatTableDataSource();
	public dataList: Array<any> = [];
	public displayedColumns: string[] = ['name', 'type', 'size', 'options'];
	public rootURL = GlobalService.databaseApiUrl;
	public isMobile: boolean = false;
	public tagList: Array<any> = [];

	public isLoading = true;
	public searchOpen = false;

	public activeOrg: Organization = null;
	public activeOrgSubscription: Subscription;

	public loadChunkFunction: Function = null;

	constructor(
		private _alertService: AlertService,
		private _favoriteService: FavoriteService,
		private _searchService: SearchService,
		private _utilsService: UtilsService,
		private _orgService: OrganizationService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _cdr: ChangeDetectorRef,
	) {

		this.isMobile = this._utilsService.getIsMobile();
		if (this.isMobile) {
			this.displayedColumns = ['name', 'options'];
		}

	}	// End-of constructor

	ngOnInit() {

		this.setupActiveOrgSubscription();
		this.dataSource.sort = this.sort;

	}	// End-of ngOnInit

	ngOnDestroy() {
		this.activeOrgSubscription?.unsubscribe();
	}

	setupActiveOrgSubscription() {
		this.activeOrgSubscription = this._orgService.getActiveOrg().subscribe(({ organization }) => {
			this.clearSearch();
			this.activeOrg = organization;
		})
	}

	loadChunk = () =>
		this.loadChunkFunction = (limit, offset) => this._searchService.getSearchChunk(this.activeOrg, this.staticSearchValue, offset).then(searchChunk => {
			const convertedData = this.convertAndAssignData(searchChunk);
			this.setTableData(convertedData);
			this._cdr.detectChanges();
			this.isLoading = false;
			return convertedData.length;
		});

	searchFilter(): void {
		// Clear after each search
		this.clearData();
		setTimeout(() => { // Necessary for proper UX updating
			this.loadChunk();
		})
		this.searchOpen = true;

		if (this.searchValue.length >= 2) {
			this.staticSearchValue = this.searchValue;
		}

	}	// End-of searchFilter

	canSearch = () => this.staticSearchValue?.length >= 2

	clearSearch = () => {
		this.searchValue = '';
		this.staticSearchValue = null;
		this.emptyText = defaultEmptyText;
		this.searchOpen = false;
		this.clearData();
	}

	clearData = () => {
		this.dataSource = new MatTableDataSource();
		this.dataList = [];
		this.isLoading = false;
		this.loadChunkFunction = null;
	}

	openSearch = () => this.searchOpen = true;
	closeSearch = () => this.searchOpen = false;

	setTableData = (tableData): void => {
		// const mergedData = uniqueByKeys(this.dataSource.data, tableData, "type");
		const oldData = [...this.dataSource.data];
		this.dataSource = new MatTableDataSource([...oldData, ...tableData]);
		this.getProjectTags();

		// this.selection.clear();
	};

	convertAndAssignData(subList): Array<any> {
		if (Array.isArray(subList)) {
			// @ts-ignore
			subList = { projects:[], images: [], models: [], project_files: [] }
		}
		return assignTypesAndConvert(subList);
	}


	handleEvent(e): void {
		if (e.type === 'favorite') {
			this.toggleFavorites(e.item);
		} else if (e.type === 'open') {
			this.openItem(e.item);
			this.closeSearch();
		}
	}


	toggleFavorites(item): void {

		let fav: Favorite = {};
		switch (item.type) {
			case 'image':
				fav.image_id = item.id;
				break;
			case 'project':
				fav.project_id = item.id;
				break;
			case 'model':
				fav.model_id = item.id;
				break;
			case 'project_file':
				fav.project_file_id = item.id;
				break;
			case 'export':
				fav.export_id = item.id;
				break;
			default:
				console.warn('Search item type not recognized: ', item);
				break;
		}
		if (item.favorited) {
			this._favoriteService.remove(item['favorited_id']).then(() => {
				item['favorited'] = false;
				this._alertService.notification(new Alert('Removed from Favorites'));
			});
		} else {
			this._favoriteService.create(fav).then(rtnData => {
				item['favorited_id'] = rtnData.favorite_id;
				item['favorited'] = true;
				this._alertService.notification(new Alert('Added to Favorites', 'star_border'));
			});
		}

	}	// End-of addToFavorites

	openItem(item): void {
		switch (item.type) {
			case "image":
				// this._router.navigate(['photos', `search-${this.searchValue}`, item.guid]);
				this._router.navigate(['photos', `search`, item.guid]);
				break;
			case "project":
				this._router.navigate(['/projects/view/' + item.id], { relativeTo: this._route, queryParams: { tab: 0 } });
				break;
			case "model":
				this._router.navigate(['/projects/view/' + item.project_id], { relativeTo: this._route, queryParams: { tab: 0 } });
				break;
			case "project_file":

				break;
			case "export":

				break;
			default:
				// code...
				break;
		}

	}	// End-of openItem


	getProjectTags(): void {
		let tagList = [];
		this.dataSource.data.forEach(project => {
			if (project.descriptors?.tags?.length) {
				tagList = tagList.concat(project.descriptors.tags);
			}
		})
		this.tagList = tagList.filter((item, pos) => tagList.indexOf(item) === pos);
	}

}	// End-of class SearchComponent
