
/* Imports */
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthenticationService } from '../../shared/services';

@Component({
	selector: 'app-onboarding',
	templateUrl: './onboarding.component.html',
	styleUrls: ['./onboarding.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingComponent implements OnInit {

	public userName: string = "";
	public imageList: Array<any> = [
		{image: 'assets/images/onboarding-1-2x.png', class: "image"},
		{image: 'assets/images/onboarding-2-2x.png', class: "image"},
		{image: 'assets/images/onboarding-3-2x.png', class: "image"},
		{image: 'assets/images/onboarding-4-2x.png', class: "image-2"},
		{image: 'assets/images/onboarding-5-2x.png', class: "image-3"}
	];

	public selectedImage: any = 0;

	constructor (
		public dialogRef: MatDialogRef<OnboardingComponent>,
		private _authService: AuthenticationService,
		private _crd: ChangeDetectorRef,
	) {
	}

	ngOnInit() {
		let user = this._authService.user;
		this.userName = user.first_name;
	}	// End-of ngOnInit

	selectionChange(event): void {
		this.selectedImage = event.selectedIndex;
	}

}	// End-of class TestComponent
